import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface ArtistProps {
  id: number
  name: string
  avatarImageKey: string
}

interface EventLineUpProps {
  artists: ArtistProps[] | undefined
  paddingLeft?: string
  paddingRight?: string
}

export function EventLineUp({
  artists,
  paddingLeft = 'pl-4',
  paddingRight = 'pr-4',
}: EventLineUpProps): ReactElement {
  return (
    <div className="relative w-full">
      <div className="relative flex w-full flex-col overflow-scroll">
        <div
          className={twMerge(
            'flex w-fit gap-4 px-4 duration-500 ease-in-out',
            paddingLeft,
            paddingRight,
          )}
        >
          {artists?.map((artist) => {
            return (
              <div
                className="flex h-fit w-[100px] flex-col gap-2"
                key={artist.id}
              >
                <img
                  src={`${import.meta.env.VITE_S3 as string}/${
                    artist.avatarImageKey
                  }`}
                  alt="Artist image"
                  className="size-[100px] rounded-md object-cover"
                />
                <span className="text-sm font-semibold text-white">
                  {artist.name}
                </span>
              </div>
            )
          })}
        </div>
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-background-main" />
      <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-background-main" />
    </div>
  )
}
