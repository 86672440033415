import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import logoGandaya from '@/assets/images/logo-no-border.png'
import { useGlobalStore } from '@/store/global'

export function Footer(): ReactElement {
  const navigate = useNavigate()

  const { scrollToEvents, setShowDownloadAppModal } = useGlobalStore()

  function goToEvents(): void {
    if (location.pathname !== '/')
      navigate('/', { state: { scrollToTop: true } })
    else scrollToEvents()
  }

  return (
    <footer className="flex w-full bg-dark-black p-8 text-white">
      <div className="flex w-1/2 flex-col gap-2 self-end lg:flex-row lg:items-center lg:gap-16 lg:self-start">
        <img
          src={logoGandaya}
          alt="Logo Gandaya"
          className="aspect-auto h-min w-[150px]"
        />
        <span>
          ©2024 Gandaya.
          <br />
          Todos os direitos reservados.
        </span>
      </div>
      <div className="flex w-1/2 items-end justify-between">
        <div className="flex flex-col gap-0 lg:grow lg:flex-row lg:items-end lg:justify-evenly">
          <div className="flex flex-col">
            <a
              className="line-clamp-1"
              href="https://pro.gandaya.dance/"
              target="_blank"
              rel="noreferrer"
            >
              Sobre
            </a>
            <button className="line-clamp-1 text-left" onClick={goToEvents}>
              Eventos
            </button>
          </div>

          <div className="flex flex-col">
            <button
              className="line-clamp-1 text-left"
              onClick={() => {
                const msg = 'Olá, gostaria de tirar uma dúvida.'
                const url = `https://wa.me/${
                  import.meta.env.VITE_SUPPORT_PHONE as string
                }?text=${msg}`
                window.open(url, '_blank')
              }}
            >
              Suporte
            </button>
            <a
              href={`${import.meta.env.VITE_ATLAS_URL as string}`}
              className="line-clamp-1"
            >
              Criar evento
            </a>
          </div>
          <div className="flex flex-col">
            <a
              href="https://www.instagram.com/gandaya.dance/"
              className="line-clamp-1"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.linkedin.com/company/gandaya/"
              className="line-clamp-1"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>

        <button
          className="rounded-full bg-white px-8 py-2"
          onClick={() => {
            setShowDownloadAppModal(true)
          }}
        >
          <span className="line-clamp-1 text-lg font-medium text-black">
            Baixar o app
          </span>
        </button>
      </div>
    </footer>
  )
}
