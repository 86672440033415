import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/Global/Button'

import {
  usePostFollowOrganization,
  useDeleteUnfollowOrganization,
} from '@/hooks/api/Organization'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { useGlobalStore } from '@/store/global'
import { twMerge } from 'tailwind-merge'

interface EventOrganizationProps {
  organizationData: {
    alias: string
    name: string
    imageKey: string
    description?: string
    followStatus: 'FOLLOWING' | 'NOT_FOLLOWING' | ''
  }
  refresh: () => void
}

export function EventOrganization({
  organizationData,
  refresh,
}: EventOrganizationProps): ReactElement {
  const navigate = useNavigate()

  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()

  const { postFollowOrganization, isLoading: isLoadingFollow } =
    usePostFollowOrganization(organizationData.alias ?? '')
  const { deleteUnfollowOrganization, isLoading: isLoadingUnfollow } =
    useDeleteUnfollowOrganization(organizationData.alias ?? '')

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      setToPath(`none`)
      return
    }

    const response = await postFollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }

    const response = await deleteUnfollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  return (
    <div
      className="flex w-full flex-col gap-2 rounded-lg bg-dark-black p-4 text-white hover:cursor-pointer"
      onClick={() => {
        navigate(`/organizations/${organizationData.alias}`)
      }}
    >
      <div className="flex gap-4">
        <div>
          <div className="w-20">
            <img
              src={`${import.meta.env.VITE_S3 as string}/${
                organizationData.imageKey
              }`}
              alt="Logo da organização"
              className="aspect-square size-full rounded-lg"
            />
          </div>
        </div>

        <div
          className={twMerge(
            'flex w-full flex-wrap items-center justify-between gap-3',
          )}
        >
          <div className="flex flex-col gap-1">
            <span className="text-xs font-medium leading-3 text-text-secondary">
              Produzido por:
            </span>
            <span className="line-clamp-1 text-2xl font-bold leading-5">
              {organizationData.name}
            </span>
          </div>
          <div className="h-8 w-48">
            <Button
              enabled={!isLoadingUnfollow && !isLoadingFollow}
              text={
                organizationData.followStatus === 'FOLLOWING'
                  ? 'Deixar de seguir'
                  : 'Seguir'
              }
              className="px-4 text-sm font-semibold"
              onClick={(e) => {
                e.stopPropagation()
                if (organizationData.followStatus === 'FOLLOWING') {
                  void handleUnfollow()
                } else {
                  void handleFollow()
                }
              }}
              isLoading={isLoadingUnfollow || isLoadingFollow}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
