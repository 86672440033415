import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { AnimatedNumber } from '@/components/Global/AnimatedNumber'
import { Spinner } from '@/components/Global/Spinner'

import { isTruthy } from '@/helpers/validation'

interface EventCallToActionProps {
  title?: string
  value?: number | string | undefined
  subtitle?: string
  buttonText: string
  buttonEnabled: boolean
  buttonOnClick: () => void
  secondaryDisabled?: boolean
  isLoading?: boolean
  hideButton?: boolean
  eventFinished?: boolean
}

export function EventCallToAction({
  title,
  value,
  subtitle,
  buttonText,
  buttonEnabled,
  buttonOnClick,
  secondaryDisabled = false,
  isLoading = false,
  hideButton = false,
  eventFinished = false,
}: EventCallToActionProps): ReactElement {
  const variations = {
    enabled: 'bg-primary-main text-black',
    disabled: secondaryDisabled
      ? 'bg-primary-dark text-white'
      : 'bg-dark-light-gray text-dark-dark-gray',
  }

  return (
    <div className="flex items-start justify-between gap-4 rounded-lg p-4 transition-height duration-200 ease-linear desktop:flex-col md:flex-row md:items-center">
      {!eventFinished && (
        <div className="flex flex-col">
          {isTruthy(title) && (
            <span className="text-sm leading-5 text-text-secondary">
              {title}
            </span>
          )}
          <div className="flex items-end">
            <div>
              {typeof value === 'number' && (
                <AnimatedNumber
                  val={value ?? 0}
                  isMoney
                  className="text-2xl font-extrabold leading-6 text-white"
                />
              )}
              {typeof value === 'string' && (
                <span className="text-xl font-extrabold leading-6 text-white">
                  {value}
                </span>
              )}
            </div>
            {isTruthy(subtitle) && (
              <span className="ml-2 text-sm leading-3 text-text-secondary">
                {subtitle}
              </span>
            )}
          </div>
        </div>
      )}
      {eventFinished ? (
        <div className="w-full px-1.5">
          <div className="relative flex h-12 w-full items-center justify-center rounded-full border border-[#595959] text-base font-bold text-[#595959] transition-colors duration-200 ease-in-out">
            Evento encerrado
          </div>
        </div>
      ) : (
        !hideButton && (
          <button
            className={twMerge(
              'relative h-12 self-end rounded-full text-lg font-bold transition-colors duration-200 ease-in-out ',
              variations[buttonEnabled ? 'enabled' : 'disabled'],
              title === undefined &&
                value === undefined &&
                subtitle === undefined
                ? 'w-full'
                : 'w-min',
            )}
            disabled={!buttonEnabled}
            onClick={buttonOnClick}
          >
            <div
              className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-[400ms] ease-in-out ${
                isLoading ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <Spinner
                borderWidth="border-4"
                borderColor="border-[#232323]/50"
                bottomBorderColor="border-b-[#232323]"
              />
            </div>

            <div
              className={`text-nowrap px-4 text-base transition-opacity duration-200 ease-in-out md:text-lg ${
                isLoading ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {buttonText}
            </div>
          </button>
        )
      )}
    </div>
  )
}
