import { type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

interface EventBriefProps {
  title?: string
  titleImage?: string
  date: string | undefined
  location: string | undefined
  highlighted?: boolean
  padding?: string
  tags?: string[]
  background?: string
  lowestTicketPrice?: number
  variant?: 'dark' | 'light' | 'white'
  size?: 'small' | 'medium' | 'large'
}

export function EventBrief({
  title,
  titleImage,
  date,
  location,
  highlighted = true,
  tags,
  background,
  variant = 'light',
  lowestTicketPrice,
  size = 'medium',
}: EventBriefProps): ReactElement {
  const titleVariants = tv({
    base: 'line-clamp-1 text-ellipsis font-semibold',
    variants: {
      text: {
        dark: 'text-text-dark',
        light: 'text-text-secondary',
        white: 'text-text-main',
      },
      size: {
        small: 'text-md leading-5',
        medium: 'text-lg leading-5',
        large: 'text-3xl leading-10',
      },
    },
  })

  const dateVariants = tv({
    base: 'max-w-full truncate text-sm font-medium ',
    variants: {
      text: {
        dark: 'text-text-dark',
        light: 'text-text-secondary',
        white: 'text-text-main',
        highlighted: 'text-primary-main',
      },
      size: {
        small: 'text-sm leading-4',
        medium: 'text-sm leading-5',
        large: 'text-2xl leading-6',
      },
    },
  })

  const locationVariants = tv({
    base: 'w-full truncate text-sm font-normal text-text-secondary',
    variants: {
      size: {
        small: 'text-sm leading-4',
        medium: 'text-sm leading-4',
        large: 'text-2xl leading-8',
      },
    },
  })

  return (
    <div
      className={
        `relative z-10 flex max-w-lg flex-col items-start justify-center ` +
        (background !== undefined ? ` ${background}` : '')
      }
    >
      {isTruthy(titleImage) && (
        <img
          src={`${import.meta.env.VITE_S3 as string}/${titleImage ?? ''}`}
          alt="Event title image"
          className="mb-1 aspect-auto h-12"
        ></img>
      )}
      {isTruthy(title) && !isTruthy(titleImage) && (
        <span className={titleVariants({ text: variant, size })}>{title}</span>
      )}
      <span
        className={dateVariants({
          text: highlighted ? 'highlighted' : variant,
          size,
        })}
      >
        {date}
      </span>
      {isTruthy(location) && (
        <span className={locationVariants({ size })}>{location}</span>
      )}
      {isTruthy(lowestTicketPrice) && (
        <span className="w-full truncate text-sm font-normal leading-4 text-text-secondary">
          A partir de {numberToReais(lowestTicketPrice!)}
        </span>
      )}
      <div className="leading-3">
        {tags?.map((tag, index) => {
          let end = ''
          if (index !== tags.length - 1) {
            end = ' • '
          }
          return (
            <span className="m-0 text-xs leading-3 text-white" key={index}>
              {tag} {end}
            </span>
          )
        })}
      </div>
    </div>
  )
}
