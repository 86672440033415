import { useState, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { OrganizationHeader } from '@/components/Organization/OrganizationHeader'
import { MainEventCardDesktop } from '@/components/Home/MainEventCard/MainEvenCardDesktop'
import { OrganizationDetailsModal } from '@/components/Organization/OrganizationDetailsModal'
import { Spinner } from '@/components/Global/Spinner'

import { HomeBase } from '@/compositions/HomeBase'

import {
  type IEvent,
  type IOrganization,
} from '@/types/hooks/api/useOrganization'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

import {
  usePostFollowOrganization,
  useDeleteUnfollowOrganization,
} from '@/hooks/api/Organization'

import { useGlobalStore } from '@/store/global'
import { useRoutingStore } from '@/store/routing'
import { useUserStore } from '@/store/user'

interface OrganizationDesktopProps {
  organization: IOrganization | undefined
  refresh: () => void
  isLoading: boolean
}
interface OrganizationEventsSplitted {
  mainNextEvent?: IEvent
  otherEventsToCome?: IEvent[]
  pastEvents: IEvent[]
}

export function OrganizationDesktop({
  organization,
  refresh,
  isLoading,
}: OrganizationDesktopProps): ReactElement {
  const [showOrganizationDetailsModal, setShowOrganizationDetailsModal] =
    useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  function splitOrganizationEvents(
    events: IEvent[],
  ): OrganizationEventsSplitted {
    const sortedEvents = events.sort((a, b) => {
      return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1
    })
    const eventsToHappen = sortedEvents.filter(
      (event) => new Date(event.startDate) >= new Date(),
    )
    const pastEvents = sortedEvents.filter(
      (event) => new Date(event.startDate) < new Date(),
    )

    return {
      mainNextEvent: eventsToHappen[0],
      otherEventsToCome: eventsToHappen.slice(1),
      pastEvents,
    }
  }

  const { pastEvents, mainNextEvent, otherEventsToCome } =
    splitOrganizationEvents(organization?.events ?? [])

  const { organizationAlias } = useParams()

  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()

  const { postFollowOrganization, isLoading: isLoadingFollow } =
    usePostFollowOrganization(organizationAlias ?? '')
  const { deleteUnfollowOrganization, isLoading: isLoadingUnfollow } =
    useDeleteUnfollowOrganization(organizationAlias ?? '')

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      setToPath(`none`)
      return
    }

    const response = await postFollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      setToPath(`none`)
      return
    }

    const response = await deleteUnfollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  if (isLoading) {
    return (
      <div className="absolute left-0 top-0 flex size-full items-center justify-center">
        <Spinner
          borderWidth="border-4"
          borderColor="border-primary-main/50"
          bottomBorderColor="border-b-primary-main"
          height="h-12"
          width="w-12"
        />
      </div>
    )
  }
  return (
    <div className="flex size-full flex-col gap-12 p-8">
      <OrganizationHeader
        organization={organization}
        openOrganizationDetailsModal={() => {
          setShowOrganizationDetailsModal(true)
        }}
        handleFollow={handleFollow}
        handleUnfollow={handleUnfollow}
        isLoading={isLoadingFollow || isLoadingUnfollow}
      />

      {mainNextEvent !== undefined && (
        <div className="flex w-full flex-col gap-4">
          <h1 className="text-2xl text-white">Próximo evento</h1>
          <MainEventCardDesktop
            title={mainNextEvent.name}
            date={datesToFormat(mainNextEvent.startDate, mainNextEvent.endDate)}
            location={mainNextEvent.locationName}
            imageKey={mainNextEvent.imageKey}
            eventAlias={mainNextEvent.alias}
            description={mainNextEvent.description}
            isUserInsterested={
              mainNextEvent.userEventStatus === 'INTERESTED' ||
              mainNextEvent.userEventStatus === 'GOING'
            }
          />
        </div>
      )}

      {otherEventsToCome !== undefined && otherEventsToCome.length > 0 && (
        <div className="flex w-full flex-col gap-4">
          <h1 className="text-2xl text-white">Outros eventos</h1>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8">
            {otherEventsToCome.map((event, index) => {
              return (
                <HomeBase.Card
                  alias={event.alias}
                  key={index}
                  imageUrl={event.imageKey}
                  title={event.name}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  location={
                    isTruthy(event.locationName)
                      ? event.locationName
                      : 'Local a definir'
                  }
                  variation="desktop"
                  onFire={event.onFire}
                  showInterest
                  isUserInsterested={
                    event.userEventStatus === 'INTERESTED' ||
                    event.userEventStatus === 'GOING'
                  }
                />
              )
            })}
          </div>
        </div>
      )}

      {pastEvents !== undefined && pastEvents.length > 0 && (
        <div className="flex w-full flex-col gap-4">
          <h1 className="text-2xl text-white">Eventos passados</h1>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8">
            {pastEvents.map((event, index) => {
              return (
                <HomeBase.Card
                  alias={event.alias}
                  key={index}
                  imageUrl={event.imageKey}
                  title={event.name}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  location={
                    isTruthy(event.locationName)
                      ? event.locationName
                      : 'Local a definir'
                  }
                  variation="desktop"
                  onFire={event.onFire}
                />
              )
            })}
          </div>
        </div>
      )}

      {showOrganizationDetailsModal && (
        <OrganizationDetailsModal
          organization={organization}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowOrganizationDetailsModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          handleFollow={handleFollow}
          handleUnfollow={handleUnfollow}
          isLoading={isLoadingFollow || isLoadingUnfollow}
        />
      )}
    </div>
  )
}
