import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface EventPartnersProps {
  partners:
    | Array<{
        imageKey: string
        url: string
      }>
    | undefined
  paddingLeft?: string
  paddingRight?: string
}

export function EventPartners({
  partners,
  paddingLeft = 'pl-4',
  paddingRight = 'pr-4',
}: EventPartnersProps): ReactElement {
  return (
    <div className="relative flex w-full flex-col overflow-hidden">
      <div
        className={twMerge(
          'scrollbar-hide w-full overflow-scroll scroll-smooth',
          paddingLeft,
          paddingRight,
        )}
      >
        <div className="flex w-max gap-3">
          {partners?.map((partner, index) => {
            return (
              <div
                key={index}
                className="h-[100px] w-[168px] overflow-hidden rounded-lg"
              >
                <img
                  src={`${import.meta.env.VITE_S3 as string}/${
                    partner.imageKey
                  }`}
                  alt="Partner image"
                  className="size-full object-cover"
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-transparent to-background-main" />
      <div className="absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-transparent to-background-main" />
    </div>
  )
}
