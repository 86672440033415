import {
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
  type ReactElement,
  useEffect,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { HeaderProfileOptions } from './HeaderProfileOptions'
import { HeaderNotifications } from './HeaderNotifications'
import { HeaderSearch } from './HeaderSearch'

import { GoSearch } from 'react-icons/go'
import { IoNotificationsOutline } from 'react-icons/io5'
import { IoIosArrowDown } from 'react-icons/io'

import logoGandaya from '@/assets/images/logo-no-border.png'

import { useGlobalStore } from '@/store/global'

import { useUserStore } from '@/store/user'

export function Header(): ReactElement {
  const [showProfileOptions, setShowProfileOptions] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)

  const headerRef = useRef<HTMLHeadingElement>(null)

  const location = useLocation()
  const navigate = useNavigate()

  const {
    setHeaderHeight,
    scrollToEvents,
    setShowLoginModal,
    setShowDownloadAppModal,
  } = useGlobalStore()
  const { userIsLogged, followRequests, imageKey } = useUserStore()
  const { showSearchBar, setShowSearchBar } = useGlobalStore()

  function goToEvents(): void {
    if (location.pathname !== '/')
      navigate('/', { state: { scrollToTop: true } })
    else scrollToEvents()
  }

  function handleOpenHeaderMiniModal(
    MiniModalToOpen: Dispatch<SetStateAction<boolean>> | ((n: boolean) => void),
    newState: boolean,
  ): void {
    if (showNotifications || showProfileOptions) {
      setShowNotifications(false)
      setShowProfileOptions(false)
      setShowSearchBar(false)

      setTimeout(MiniModalToOpen, 200, newState)
    } else {
      MiniModalToOpen(newState)
    }
  }

  useEffect(() => {
    setShowNotifications(false)
    setShowProfileOptions(false)
    setShowSearchBar(false)
  }, [location])

  return (
    <header
      className="relative z-30 flex w-full items-center justify-between bg-dark-black px-8 py-5"
      ref={headerRef}
    >
      <img
        src={logoGandaya}
        alt="Logo Gandaya"
        className="w-[135px] pt-4 hover:cursor-pointer"
        onLoad={() => {
          setHeaderHeight(headerRef.current?.offsetHeight ?? 0)
        }}
        onClick={() => {
          navigate('/')
        }}
      />
      <div className="flex items-center gap-3 md:gap-8">
        <button
          className="line-clamp-1 text-lg text-white"
          onClick={goToEvents}
        >
          Ver eventos
        </button>
        <a
          className="line-clamp-1 text-lg text-white"
          href="https://pro.gandaya.dance/"
          target="_blank"
          rel="noreferrer"
        >
          Sobre
        </a>
        {!userIsLogged && (
          <button
            className="line-clamp-1 text-lg text-white"
            onClick={() => {
              setShowLoginModal(true)
            }}
          >
            Login
          </button>
        )}
        <div className="flex gap-2">
          <button
            className="flex size-[38px] items-center justify-center rounded-full bg-background-main"
            onClick={() => {
              handleOpenHeaderMiniModal(setShowSearchBar, !showSearchBar)
            }}
          >
            <GoSearch color={showSearchBar ? '#D5FF5C' : 'white'} size={19} />
          </button>
          {userIsLogged && (
            <button
              className="relative flex size-[38px] items-center justify-center rounded-full bg-background-main"
              onClick={() => {
                handleOpenHeaderMiniModal(
                  setShowNotifications,
                  !showNotifications,
                )
              }}
            >
              <IoNotificationsOutline
                color={showNotifications ? '#D5FF5C' : 'white'}
                size={19}
                className="transition-colors duration-200 ease-in-out"
              />
              {followRequests > 0 && (
                <span className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-tonal-red text-xs font-black text-white">
                  {followRequests}
                </span>
              )}
            </button>
          )}
        </div>
        {!userIsLogged && (
          <button
            className="rounded-full bg-white px-12 py-2"
            onClick={() => {
              setShowDownloadAppModal(true)
            }}
          >
            <span className="line-clamp-1 text-sm font-medium">
              Baixar o app
            </span>
          </button>
        )}
        {userIsLogged && (
          <button
            className="relative"
            onClick={() => {
              handleOpenHeaderMiniModal(
                setShowProfileOptions,
                !showProfileOptions,
              )
            }}
          >
            <img
              src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
              alt="Profile image"
              className="size-[50px] rounded-full"
            />

            <motion.div
              className="absolute -bottom-1 -right-1 flex size-5 items-center justify-center rounded-full border border-dark-black bg-primary-main"
              animate={{
                rotate: showProfileOptions ? 180 : 0,
                paddingTop: showProfileOptions ? 0 : 2,
              }}
              transition={{ duration: 0.2, type: 'tween' }}
            >
              <IoIosArrowDown color="#181818" size={14} />
            </motion.div>
          </button>
        )}
      </div>
      <HeaderProfileOptions
        showProfileOptions={showProfileOptions}
        setShowProfileOptions={setShowProfileOptions}
      />
      {userIsLogged && (
        <HeaderNotifications
          showNotifications={showNotifications}
          setShowNotifications={setShowNotifications}
        />
      )}
      <HeaderSearch
        showSearch={showSearchBar}
        setShowSearch={setShowSearchBar}
      />
    </header>
  )
}
