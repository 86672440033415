import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

import { type IOrganization } from '@/types/hooks/api/useOrganization'

interface OrganizationDetailsModalProps {
  organization: IOrganization | undefined
  closeModal: () => void
  isParentClosing: boolean
  handleFollow: () => Promise<void>
  handleUnfollow: () => Promise<void>
  isLoading: boolean
}

export function OrganizationDetailsModal({
  organization,
  closeModal,
  isParentClosing,
  handleFollow,
  handleUnfollow,
  isLoading,
}: OrganizationDetailsModalProps): ReactElement {
  const followersText =
    organization?.followers === 1 ? 'seguidor' : 'seguidores'

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variation="dark"
    >
      <div className="flex w-full flex-col gap-4 p-4">
        <div className="flex items-center gap-8">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${organization?.imageKey ?? ''}`}
            alt="Organization image"
            className="size-[165px] shrink-0 rounded-full"
          />
          <div className="flex w-full flex-col">
            <h1 className="text-3xl text-white">{organization?.name}</h1>
            <span className="text-white">
              <strong>{organization?.followers}</strong> {followersText}
            </span>
            <div className="h-8 w-48">
              <Button
                enabled={!isLoading}
                text={
                  organization?.followStatus === 'FOLLOWING'
                    ? 'Deixar de seguir'
                    : 'Seguir'
                }
                className="mt-4 px-4 py-1.5 text-sm font-semibold"
                onClick={(e) => {
                  e.stopPropagation()
                  if (organization?.followStatus === 'FOLLOWING') {
                    void handleUnfollow()
                  } else {
                    void handleFollow()
                  }
                }}
                isLoading={isLoading}
                customSpinnerHeight="h-6"
                customSpinnerWidth="w-6"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold text-white">Sobre</h1>
          <div className="relative h-full max-h-[212px]">
            <div className="absolute -top-0.5 left-0 h-2.5 w-full bg-gradient-to-b from-background-main to-transparent" />
            <div className="absolute -bottom-0.5 left-0 h-2.5 w-full bg-gradient-to-t from-background-main to-transparent" />
            <div className="size-full max-h-[212px] overflow-scroll py-2">
              <span className="p-0 text-white">
                {organization?.description}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-white">Links</h1>
          <div className="flex items-center justify-between">
            <div className="flex w-fit gap-4">
              <BsInstagram color="white" size={20} />
              <BsTiktok color="white" size={20} />
            </div>
            <Button
              enabled={!isLoading}
              text={
                organization?.followStatus === 'FOLLOWING'
                  ? 'Deixar de seguir'
                  : 'Seguir'
              }
              className="w-fit px-4 py-1.5 text-sm font-semibold"
              onClick={(e) => {
                e.stopPropagation()
                if (organization?.followStatus === 'FOLLOWING') {
                  void handleUnfollow()
                } else {
                  void handleFollow()
                }
              }}
              isLoading={isLoading}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          </div>
        </div> */}
      </div>
    </Modal>
  )
}
