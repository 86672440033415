import { type ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { EventBase } from '@/compositions/EventBase'

import { useTicketsStore } from '@/store/tickets'
import { useEventStore } from '@/store/event'
import { useInterestStore } from '@/store/interest'
import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'

import { type IEventData } from '@/types/hooks/api/useEvent'

import { isTruthy } from '@/helpers/validation'

import { usePixelTrackPageView } from '@/hooks/pixel'
import { useGetUsersStatus } from '@/hooks/api/Event'
import { isEventFinished } from '@/helpers/isEventFinished'

interface EventStoryProps {
  eventData: IEventData | undefined
  refresh: () => void
}

export function EventStory({
  eventData,
  refresh,
}: EventStoryProps): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showInterestModal, setShowInterestModal] = useState(false)

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { setTicketQuantity, setPixelId } = useEventStore()
  const { resetTickets } = useTicketsStore()
  const {
    setRefreshData,
    updateAll,
    setRefreshCurrentUserData,
    alias: interestAlias,
  } = useInterestStore()
  const { userIsLogged } = useUserStore()
  const { showSuccessSnack } = useSnackbarStore()

  const searchParams = new URLSearchParams(document.location.search)
  const link = searchParams.get('link')

  const { trackPageView } = usePixelTrackPageView()
  const { usersData, refresh: refreshUsersStatus } = useGetUsersStatus(
    eventAlias!,
  )

  function handleClick(): void {
    navigate('tickets')
  }

  useEffect(() => {
    resetTickets()
    useTicketsStore.persist.clearStorage()
    setTicketQuantity(0)

    if (isTruthy(link)) {
      showSuccessSnack({
        title: 'Cupom aplicado!',
        description: `${link ?? ''}`,
        displayMode: 'FOOTER',
      })
    }
  }, [])

  useEffect(() => {
    // Pixel track
    if (isTruthy(eventData?.integrationCredentials?.pixelId))
      trackPageView(eventData?.integrationCredentials?.pixelId ?? '')
  }, [window.fbq, eventData])

  useEffect(() => {
    setPixelId(eventData?.integrationCredentials?.pixelId ?? '')
  }, [eventData])

  useEffect(() => {
    setRefreshData(() => {
      void refreshUsersStatus()
    })
    setRefreshCurrentUserData(refresh)
  }, [refresh])

  useEffect(() => {
    if (usersData !== undefined) updateAll(usersData, eventAlias)
  }, [usersData])

  // Updates users status once the user logs in
  useEffect(() => {
    void refreshUsersStatus()
  }, [userIsLogged])

  const footerTitle =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? 'Ingressos' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0
        ? 'Ingresso'
        : eventData?.lowestTicketPrice === null
          ? 'Ingressos'
          : 'A partir de'

  const footerValue =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? 'Disponíveis' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0
        ? 'Grátis'
        : eventData?.lowestTicketPrice === null
          ? 'Indisponíveis'
          : (eventData?.lowestTicketPrice ?? 0)

  const footerSubtitle =
    eventAlias === 'sal-de-caraiva' // TEMPORARY INIT
      ? '' // TEMPORARY END
      : eventData?.lowestTicketPrice === 0 ||
          eventData?.lowestTicketPrice === null
        ? ''
        : '+ taxa'

  const footerButtonText =
    eventData?.lowestTicketPrice === 0 ? 'Reservar agora' : 'Comprar agora'

  const isPastEvent =
    eventData?.date.endDate !== undefined
      ? isEventFinished(eventData?.date.endDate)
      : false

  return (
    <>
      <EventBase.Root isMobile={true}>
        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {!eventData!.hideSocial && (
            <div className="p-4 pb-0 pt-3">
              <EventBase.Interest
                openEventInterestModal={() => {
                  setShowInterestModal(true)
                }}
                initialUserStatus={eventData?.userStatus}
              />
            </div>
          )}
          {isTruthy(eventData?.reactions) && !eventData!.hideReactions && (
            <div className="px-4 pt-4">
              <EventBase.Reactions
                eventId={eventData?.id ?? 0}
                eventReactions={eventData?.reactions}
              />
            </div>
          )}
          <div className="flex flex-col gap-8 pt-2">
            {isTruthy(eventData?.description) && (
              <div className="px-4 pt-2">
                <EventBase.Description text={eventData?.description} />
              </div>
            )}
            {isTruthy(eventData?.lineup) &&
              (eventData?.lineup?.length ?? 0) > 0 && (
                <div className="flex flex-col gap-4">
                  <span className="pl-4 text-2xl font-bold text-white">
                    Line-up
                  </span>
                  <EventBase.LineUp artists={eventData?.lineup} />
                </div>
              )}
            {isTruthy(eventData?.spotify) && (
              <div className="px-4">
                <EventBase.Playlist spotify={eventData?.spotify} />
              </div>
            )}
            {(isTruthy(eventData?.location) ||
              isTruthy(eventData?.terms) ||
              isTruthy(eventData?.faq)) && (
              <div className="flex flex-col gap-4 px-4">
                <span className="text-2xl font-bold text-white">Infos</span>
                <EventBase.Info.Time eventData={eventData} />
                {isTruthy(eventData?.location) && (
                  <EventBase.Info.Location
                    name={eventData?.location.name}
                    fullAddress={eventData?.location.address}
                    latitude={eventData?.location.latitude}
                    longitude={eventData?.location.longitude}
                  />
                )}
                {isTruthy(eventData?.terms) && (
                  <EventBase.Info.Terms terms={eventData?.terms} />
                )}
                {isTruthy(eventData?.faq) && eventData!.faq.length > 0 && (
                  <EventBase.Info.Faq questionsAnswers={eventData?.faq} />
                )}
              </div>
            )}
            {eventAlias === interestAlias && !eventData!.hideSocial && (
              <EventBase.Bit title="RSVPs" className="px-4">
                <EventBase.RSVPs
                  openEventInterestModal={() => {
                    setShowInterestModal(true)
                  }}
                />
              </EventBase.Bit>
            )}
            <EventBase.Bit className="m-0">
              {eventData?.eventType === 'CLOSE_FRIENDS' ? (
                <EventBase.CreateEvent
                  title="Crie seu evento close friends!"
                  subtitle="Clique aqui e crie sua festa em minutos!"
                />
              ) : (
                <EventBase.CreateEvent
                  title="Crie seu evento com a gente!"
                  subtitle="Clique aqui e crie sua festa em minutos!"
                />
              )}
            </EventBase.Bit>
          </div>
          {isTruthy(eventData?.partners) && eventData!.partners.length > 0 && (
            <div className="flex flex-col gap-4 py-4">
              <span className="pl-4 text-2xl font-bold text-white">
                Parceiros
              </span>
              <EventBase.Partners partners={eventData?.partners} />
            </div>
          )}
          {isTruthy(eventData?.organization) &&
            eventData?.hosts.length === 0 && (
              <div className="px-4 py-2">
                <EventBase.Organization
                  organizationData={{
                    alias: eventData?.organization.alias ?? '',
                    name: eventData?.organization.name ?? '',
                    imageKey: eventData?.organization.imageKey ?? '',
                    description: eventData?.organization.description ?? '',
                    followStatus:
                      eventData?.organization.organizationFollowStatus ?? '',
                  }}
                  refresh={refresh}
                />
              </div>
            )}
          {isTruthy(eventData?.hosts.length) && (
            <div className="px-4">
              <EventBase.Hosts hosts={eventData?.hosts} />
            </div>
          )}
        </motion.div>
        {showInterestModal && (
          <EventBase.InterestModal
            isParentClosing={isParentClosing}
            closeModal={() => {
              setIsParentClosing(true)
              setTimeout(setIsParentClosing, 400, false)
              setTimeout(setShowInterestModal, 400, false)
            }}
            interestData={usersData}
          />
        )}
      </EventBase.Root>
      <EventBase.Footer
        title={footerTitle}
        value={footerValue}
        subtitle={footerSubtitle}
        buttonText={footerButtonText}
        buttonEnabled={true}
        buttonOnClick={() => {
          handleClick()
        }}
        hideButton={eventData?.lowestTicketPrice === null}
        eventFinished={isPastEvent}
      />
    </>
  )
}
