import { useState, type ReactElement } from 'react'

import { Button } from '@/components/Global/Button'

import {
  useDeleteUnfollow,
  usePostCreateFollowRequest,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { ERROR_TO_CANCEL_FOLLOW, ERROR_TO_CREATE_FOLLOW } from '@/errors'

import { type IUserData } from '@/types/hooks/api/useProfile'

import { PiUserSwitch } from 'react-icons/pi'

import { isTruthy } from '@/helpers/validation'

interface ProfileCardProps {
  userData?: IUserData
  isAuthUser: boolean
  setShowProfilePictureModal: (value: boolean) => void
  openEditProfileModal: () => void
}

export function ProfileCard({
  userData,
  isAuthUser,
  setShowProfilePictureModal,
  openEditProfileModal,
}: ProfileCardProps): ReactElement {
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()
  const { userIsLogged } = useUserStore()
  const { showErrorSnack } = useSnackbarStore()

  const [followRequestStatus, setFollowRequestStatus] = useState<
    'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING' | undefined
  >(userData?.followRequest.status)

  const { postCreateFollowRequest, isLoading: followRequestIsLoading } =
    usePostCreateFollowRequest()
  const { deleteUnfollow, isLoading: unfollowRequestIsLoading } =
    useDeleteUnfollow()
  const { putCancelFollowRequest, isLoading: cancelFollowRequestIsLoading } =
    usePutCancelFollowRequest()

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await postCreateFollowRequest(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('PENDING')
    } else {
      showErrorSnack(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await deleteUnfollow(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('NOT_FOLLOWING')
    } else {
      showErrorSnack(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleCancelFollowRequest(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await putCancelFollowRequest(userData?.followRequest.id)
    if (response.status === 200) {
      setFollowRequestStatus('NOT_FOLLOWING')
    } else {
      showErrorSnack(ERROR_TO_CANCEL_FOLLOW)
    }
  }

  return (
    <div className="relative flex w-full flex-col items-center justify-between gap-3 overflow-hidden rounded-xl bg-black/50 p-4 backdrop-blur-sm">
      <div className="flex max-w-full items-center gap-4">
        <div className="relative aspect-square w-1/4 min-w-[64px]  rounded-full">
          {isTruthy(userData?.imageKey) ? (
            <img
              src={`${import.meta.env.VITE_S3 as string}/${
                userData?.imageKey ?? ''
              }`}
              alt="Profile pic"
              className="size-full overflow-hidden rounded-full"
            />
          ) : (
            <div className="relative flex size-full items-center justify-center rounded-full bg-white/10 p-2">
              <span className="text-center text-xs font-bold text-white">
                SEM FOTO DE PERFIL
              </span>
            </div>
          )}
          {isAuthUser && (
            <div
              className="absolute bottom-0 right-0 flex aspect-square h-1/3 items-center justify-center rounded-full bg-primary-main hover:cursor-pointer"
              onClick={() => {
                setToPath('none')
                setShowProfilePictureModal(true)
              }}
            >
              <PiUserSwitch size={'75%'} color="black" />
            </div>
          )}
        </div>
        <div className="flex w-full justify-evenly">
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.numberOfEvents}
            </span>
            <span className="text-sm leading-4 text-white">Eventos</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.followers}
            </span>
            <span className="text-sm leading-4 text-white">Seguidores</span>
          </div>
          <div className="flex flex-col items-center gap-1">
            <span className="text-lg font-bold leading-4 text-white">
              {userData?.following}
            </span>
            <span className="text-sm leading-4 text-white">Seguindo</span>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <span className="w-full truncate font-medium leading-4 text-white">
          {`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}
        </span>
        {isAuthUser && (
          <button
            className="text-nowrap rounded-full bg-white px-4 py-1 text-xs font-bold"
            onClick={openEditProfileModal}
          >
            Editar perfil
          </button>
        )}
      </div>

      {!isAuthUser && (
        <div className="h-8 w-full">
          {followRequestStatus === 'NOT_FOLLOWING' && (
            <Button
              enabled={!followRequestIsLoading}
              text="Seguir"
              className="text-base"
              isLoading={followRequestIsLoading}
              onClick={handleFollow}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}

          {followRequestStatus === 'PENDING' && (
            <Button
              enabled={!cancelFollowRequestIsLoading}
              isLoading={cancelFollowRequestIsLoading}
              text="Solicitação enviada"
              className="text-base brightness-50"
              onClick={handleCancelFollowRequest}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}

          {followRequestStatus === 'FOLLOWING' && (
            <Button
              enabled={!unfollowRequestIsLoading}
              text="Deixar de seguir"
              className="text-base"
              isLoading={unfollowRequestIsLoading}
              onClick={handleUnfollow}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}
        </div>
      )}
    </div>
  )
}
