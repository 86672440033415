import { useEffect, useRef, useState, type ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { HomeBase } from '@/compositions/HomeBase'

import { useGlobalStore } from '@/store/global'

import { Button } from '@/components/Global/Button'

import homeBg from '@/assets/images/home-bg.png'

import { useGetHomeEvents } from '@/hooks/api/Home'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'
import { isMoreThan24HoursFromNow } from '@/helpers/date'

import { type SectionData } from '@/types/hooks/api/useHomeEvents'
import { MarketplaceWarningModal } from '@/components/Home/MarketplaceWarningModal'

interface NavigationState {
  scrollToTop: boolean
  marketPlaceRedirectUrl?: string
}

export function HomeDesktop(): ReactElement {
  const [otherEvents, setOtherEvents] = useState<SectionData[]>([])
  const [pastEvents, setPastEvents] = useState<SectionData[]>([])
  const [noNewEvents, setNoNewEvents] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showMarketplaceWarningModal, setShowMarketplaceWarningModal] =
    useState(false)

  const location = useLocation()
  const locationState = location.state as NavigationState

  const eventsDivRef = useRef<HTMLDivElement>(null)

  const { headerHeight, setScrollToEvents } = useGlobalStore()

  const { homeEventsData, isLoading, fetchOtherEvents } = useGetHomeEvents()

  async function loadMoreEvents(): Promise<void> {
    // Aggregate already shown event ids
    const idsToExclude: number[] = []

    idsToExclude.push(homeEventsData?.main?.id ?? 0)
    homeEventsData?.sections.forEach((sectionObject) => {
      sectionObject.events.forEach((event) => {
        idsToExclude.push(event.id)
      })
    })
    otherEvents?.forEach((event) => {
      idsToExclude.push(event.id)
    })
    pastEvents?.forEach((event) => {
      idsToExclude.push(event.id)
    })

    const result = await fetchOtherEvents(idsToExclude)

    if (result.otherEvents !== undefined) {
      const newEvents: SectionData[] = result.otherEvents
      if (newEvents.length < 10) {
        setNoNewEvents(true)
      }

      const otherEventsFiltered = newEvents.filter((event) => {
        const eventEndDate = new Date(event.endDate)
        return !isMoreThan24HoursFromNow(eventEndDate)
      })
      setOtherEvents((prev) => [...prev, ...(otherEventsFiltered ?? [])])

      const pastEventsFiltered = newEvents.filter((event) => {
        const eventEndDate = new Date(event.endDate)
        return isMoreThan24HoursFromNow(eventEndDate)
      })
      setPastEvents((prev) => [...prev, ...(pastEventsFiltered ?? [])])
    }
  }

  useEffect(() => {
    if (homeEventsData?.otherEvents != null) {
      const otherEventsFiltered = homeEventsData?.otherEvents.filter(
        (event) => {
          const eventEndDate = new Date(event.endDate)
          return !isMoreThan24HoursFromNow(eventEndDate)
        },
      )
      setOtherEvents(otherEventsFiltered)

      const pastEventsFiltered = homeEventsData?.otherEvents.filter((event) => {
        const eventEndDate = new Date(event.endDate)
        return isMoreThan24HoursFromNow(eventEndDate)
      })
      setPastEvents(pastEventsFiltered)

      if (homeEventsData?.otherEvents.length < 10) {
        setNoNewEvents(true)
      }
    }
  }, [homeEventsData])

  useEffect(() => {
    setScrollToEvents(() => {
      eventsDivRef.current!.scrollIntoView({ behavior: 'smooth' })
    })
  }, [eventsDivRef])

  useEffect(() => {
    if (locationState?.marketPlaceRedirectUrl !== undefined) {
      setShowMarketplaceWarningModal(true)
    }
  }, [locationState])

  return (
    <div className="flex flex-col">
      <div
        className="absolute left-0 flex w-full flex-col justify-center"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <img
          src={homeBg}
          className="absolute -z-10 size-full object-cover"
          alt="Home background"
          onLoad={() => {
            if (locationState?.scrollToTop)
              eventsDivRef.current!.scrollIntoView({ behavior: 'smooth' })
          }}
        />

        <div className="flex flex-col gap-8 p-8">
          <span className="z-10 font-realce text-[100px] font-black leading-[90px] text-white">
            O MELHOR JEITO
            <br /> DE ORGANIZAR <br />
            UMA FESTA
          </span>

          <span className="text-lg leading-6 text-white">
            Eventos incríveis começam com a Gandaya.
            <br /> Sem custos, sem limites.
          </span>

          <a
            href={`${import.meta.env.VITE_ATLAS_URL as string}`}
            className="w-fit rounded-full bg-white px-6 py-2 text-xl font-medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            Criar evento
          </a>

          <div className="absolute bottom-0 left-0 h-10 w-full bg-gradient-to-b from-transparent to-background-main" />
        </div>
      </div>
      <div style={{ height: `calc(100vh - ${headerHeight}px)` }} />
      <div className="flex flex-col gap-16 p-4 pb-8 pl-0" ref={eventsDivRef}>
        {homeEventsData !== undefined && (
          <HomeBase.Section title="Em destaque">
            <HomeBase.MainDesktop
              eventAlias={homeEventsData?.main.alias}
              imageKey={homeEventsData?.main.splashImageKey}
              titleImage={homeEventsData?.main.titleImageKey}
              title={homeEventsData?.main.title}
              date={datesToFormat(
                homeEventsData.main.date,
                homeEventsData.main.endDate,
              )}
              location={
                isTruthy(homeEventsData?.main.location)
                  ? homeEventsData?.main.location
                  : 'Local a definir'
              }
              tags={homeEventsData?.main.tags}
              onFire={homeEventsData?.main.onFire}
              lowestTicketPrice={homeEventsData?.main.lowestTicketPrice}
              description={homeEventsData?.main.description}
              isUserInsterested={
                homeEventsData?.main.userEventStatus === 'INTERESTED' ||
                homeEventsData?.main.userEventStatus === 'GOING'
              }
            />
          </HomeBase.Section>
        )}

        {homeEventsData?.sections
          ?.filter((section) => section.events.length > 0)
          .map((section, index) => {
            return (
              <HomeBase.Section title={section.title} key={index}>
                <div className="flex flex-col gap-1.5">
                  <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-8">
                    {section.events.map((event, index) => {
                      return (
                        <HomeBase.Card
                          alias={event.alias}
                          key={index}
                          imageUrl={event.splashImageKey}
                          title={event.title}
                          startDate={event.date}
                          endDate={event.endDate}
                          location={
                            isTruthy(event.location)
                              ? event.location
                              : 'Local a definir'
                          }
                          variation="desktop"
                          onFire={event.onFire}
                          lowestTicketPrice={event.lowestTicketPrice}
                          showInterest
                          isUserInsterested={
                            event.userEventStatus === 'INTERESTED' ||
                            event.userEventStatus === 'GOING'
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              </HomeBase.Section>
            )
          })}

        {otherEvents.length > 0 && (
          <HomeBase.Section title="Outros eventos">
            <div className="flex flex-col gap-1.5">
              <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-8">
                {otherEvents.map((event, index) => {
                  return (
                    <HomeBase.Card
                      alias={event.alias}
                      key={index}
                      imageUrl={event.splashImageKey}
                      title={event.title}
                      startDate={event.date}
                      endDate={event.endDate}
                      location={
                        isTruthy(event.location)
                          ? event.location
                          : 'Local a definir'
                      }
                      variation="desktop"
                      onFire={event.onFire}
                      lowestTicketPrice={event.lowestTicketPrice}
                      showInterest
                      isUserInsterested={
                        event.userEventStatus === 'INTERESTED' ||
                        event.userEventStatus === 'GOING'
                      }
                    />
                  )
                })}
              </div>
            </div>
          </HomeBase.Section>
        )}

        {pastEvents.length > 0 && (
          <HomeBase.Section title="Eventos passados">
            <div className="flex flex-col gap-1.5">
              <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-8">
                {pastEvents.map((event, index) => {
                  return (
                    <HomeBase.Card
                      alias={event.alias}
                      key={index}
                      imageUrl={event.splashImageKey}
                      title={event.title}
                      startDate={event.date}
                      endDate={event.endDate}
                      location={
                        isTruthy(event.location)
                          ? event.location
                          : 'Local a definir'
                      }
                      variation="desktop"
                      onFire={event.onFire}
                      lowestTicketPrice={event.lowestTicketPrice}
                    />
                  )
                })}
              </div>
            </div>
          </HomeBase.Section>
        )}
        {!noNewEvents && homeEventsData !== undefined && (
          <div className="flex h-10 w-full justify-center">
            <div className="w-[190px]">
              <Button
                enabled={!isLoading}
                isLoading={isLoading}
                text="Carregar mais eventos"
                onClick={loadMoreEvents}
                className="border border-white bg-transparent px-4 text-sm text-white"
                spinnerVariant="white"
                customSpinnerHeight="h-6"
                customSpinnerWidth="w-6"
              />
            </div>
          </div>
        )}
      </div>
      {showMarketplaceWarningModal && (
        <MarketplaceWarningModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setShowMarketplaceWarningModal, 400, false)
            setTimeout(setIsParentClosing, 400, false)
          }}
          linkUrl={locationState?.marketPlaceRedirectUrl as string}
        />
      )}
    </div>
  )
}
