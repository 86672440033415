import { useEffect, useState, type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { TicketSelector } from '@/components/Global/TicketSelector'

import { EventBase } from '@/compositions/EventBase'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

import { useEventStore } from '@/store/event'
import { useTicketsStore } from '@/store/tickets'

import { IoClose } from 'react-icons/io5'

interface EventTicketsDesktopProps {
  isLoadingFetch: boolean
  isLoadingCheck: boolean
  canSelectTickets: boolean
  handleGoToCheckout: () => Promise<boolean>
  promo: string
  setPromo: (promo: string) => void
}

export function EventTicketsDesktop({
  canSelectTickets,
  handleGoToCheckout,
  isLoadingCheck,
  isLoadingFetch,
  promo,
  setPromo,
}: EventTicketsDesktopProps): ReactElement {
  const [total, setTotal] = useState(0)
  const [showCallToAction, setShowCallToAction] = useState(true)

  const {
    tickets,
    totalHasFee,
    addTicket,
    removeTicket,
    canAdd,
    canRemove,
    getTotal,
  } = useTicketsStore()
  const { currentEvent, setTicketQuantity } = useEventStore()
  const { resetTickets } = useTicketsStore()

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const hasTicketSelected = tickets.some((t) => t.quantitySelected > 0)

  useEffect(() => {
    setTotal(getTotal())
  }, [tickets])

  useEffect(() => {
    // Lock the scroll
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <>
      <div className="flex size-full flex-col items-center justify-center">
        <div className="flex w-full max-w-[500px] flex-col gap-4">
          {currentEvent !== undefined && (
            <>
              <div className="absolute bottom-8 left-8 z-30 flex items-center gap-2">
                <span className="text-lg font-semibold text-primary-main">
                  Ingressos
                </span>
                <span className="text-lg font-semibold text-white">{'->'}</span>
                <span className="font-regular text-lg text-white">
                  Pagamento
                </span>
              </div>

              <button
                className="absolute right-8 top-8 z-30 flex size-8 items-center justify-center rounded-full bg-white"
                onClick={() => {
                  resetTickets()
                  useTicketsStore.persist.clearStorage()
                  setTicketQuantity(0)
                  navigate(`/events/${eventAlias ?? ''}`)
                }}
              >
                <IoClose color="#181818" size={22} />
              </button>

              <div className="w-full">
                <EventBase.Thumbnail
                  date={datesToFormat(
                    currentEvent.date.startDate,
                    currentEvent.date.endDate,
                  )}
                  title={currentEvent.title}
                  location={
                    currentEvent?.location?.name ??
                    currentEvent?.location?.address ??
                    'Local a definir'
                  }
                  imageKey={currentEvent.imageKey}
                  briefVariant="white"
                  highlighted
                  imageVariant="large"
                />
              </div>
            </>
          )}
          <div className="relative">
            <div className="z-30 flex w-full flex-col gap-6 overflow-hidden rounded-md pb-6 pt-0 desktop:rounded-md desktop:bg-background-main desktop:pb-4">
              {isLoadingFetch ? (
                <div className="flex w-full flex-col items-center justify-center gap-2 p-4 desktop:h-[400px]">
                  <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
                  <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
                  <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
                  <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
                </div>
              ) : tickets.length === 0 ? (
                <div className="flex items-center justify-center desktop:h-[400px]">
                  <span className="mt-4 w-full text-center text-sm text-white">
                    Nenhum ingresso disponível no momento.
                  </span>
                </div>
              ) : (
                <div className="relative">
                  <div className="absolute top-0 z-10 h-4 w-full bg-gradient-to-b from-background-main to-transparent" />
                  <div className="absolute bottom-0 z-10 h-4 w-full bg-gradient-to-t from-background-main to-transparent" />
                  <div className="flex flex-col gap-2 p-4 desktop:h-[400px] desktop:overflow-scroll">
                    {tickets?.map((ticket) => {
                      const title = isTruthy(ticket.batchDescription)
                        ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                        : ticket.ticketSpecDescription
                      return (
                        <TicketSelector
                          key={String(ticket.ticketSpecId) + ticket.promo}
                          title={title}
                          price={ticket.price}
                          promo={ticket.promo}
                          hasFee={ticket.hasFee}
                          quantitySelected={ticket.quantitySelected}
                          finalPrice={ticket.finalPrice}
                          variant="dark"
                          addTicket={() => {
                            if (!canSelectTickets) return
                            addTicket(ticket)
                          }}
                          removeTicket={() => {
                            if (!canSelectTickets) return
                            removeTicket(ticket)
                          }}
                          canAddTicket={() => {
                            return canAdd(ticket)
                          }}
                          canRemoveTicket={() => {
                            return canRemove(ticket)
                          }}
                          canFastSelect={false}
                          extraDescription={ticket.extraDescription}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
              <div className="z-20 w-full px-4">
                <EventBase.AddPromo
                  promo={promo}
                  setPromo={setPromo}
                  variant="full"
                />
              </div>
            </div>
            <motion.div
              className="absolute bottom-0 -z-10 w-full rounded-lg bg-background-main"
              key="call-to-action-tickets"
              initial={{ y: -10 }}
              animate={{ y: showCallToAction ? 90 : -10 }}
              exit={{ y: -10 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
            >
              <EventBase.CallToAction
                title={
                  hasTicketSelected ? 'Valor total' : 'Selecione seus ingressos'
                }
                value={hasTicketSelected ? total : 0}
                subtitle={totalHasFee() ? '+ taxa' : ''}
                buttonText={
                  hasTicketSelected && total === 0
                    ? 'Reservar agora'
                    : 'Comprar agora'
                }
                buttonEnabled={
                  hasTicketSelected && !isLoadingCheck && !isLoadingFetch
                }
                buttonOnClick={async () => {
                  const ticketsValidated = await handleGoToCheckout()
                  if (ticketsValidated) setShowCallToAction(false)
                }}
                isLoading={isLoadingCheck}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}
