import { useState, useEffect, useRef, type ReactElement } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { SnackBar } from '@/components/Global/SnackBar'
import { DeleteIcon } from '@/components/Global/Icons/DeleteIcon'

import { useSnackbarStore } from '@/store/snackbar'

interface ModalMobileProps {
  closeModal?: () => void
  children: ReactElement
  disableTouchToClose?: boolean
  isParentClosing?: boolean
  hasRoundedBorder?: boolean
  variation?: 'dark' | 'light'
  // isParentClosing is responsible for closing the Modal internally before the parent component unmounts it
}

export function ModalMobile({
  closeModal,
  children,
  disableTouchToClose = false,
  isParentClosing = false,
  hasRoundedBorder = true,
  variation = 'light',
}: ModalMobileProps): ReactElement {
  const [renderOverlay, setRenderOverlay] = useState(true)
  const [renderModal, setRenderModal] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [overlayFitsSnackBar, setOverlayFitsSnackBar] = useState(true)

  const modalRef = useRef<HTMLDivElement>(null)
  const modalDivRef = useRef<HTMLDivElement>(null)

  const { snack, displayMode } = useSnackbarStore()

  function handleModalClose(): void {
    document.body.style.overflow = ''
    setRenderModal(false)
    setTimeout(setRenderOverlay, 200, false)
    if (closeModal !== undefined) setTimeout(closeModal, 200)
  }

  function checkWindowHeight(): void {
    if (modalRef.current === null) return
    if (modalRef.current?.clientHeight === window.innerHeight) {
      modalDivRef.current!.style.borderRadius = `0px`
      setFullScreen(true)
    } else {
      modalDivRef.current!.style.borderTopLeftRadius = `8px`
      modalDivRef.current!.style.borderTopRightRadius = `8px`
      setFullScreen(false)
    }
    if (window.innerHeight - modalRef.current.clientHeight < 80) {
      setOverlayFitsSnackBar(false)
    } else {
      setOverlayFitsSnackBar(true)
    }
  }

  // Render overlay first, then render modal
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(setRenderModal, 200, true)
  }, [])

  useEffect(() => {
    document.body.style.overflow = ''
    if (isParentClosing) handleModalClose()
  }, [isParentClosing])

  useEffect(() => {
    const handleResize = (): void => {
      checkWindowHeight()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <AnimatePresence>
      {renderOverlay && (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-30 h-max min-h-full w-full backdrop-blur-sm backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={() => {
            if (!disableTouchToClose) handleModalClose()
          }}
        />
      )}

      {renderModal && (
        <motion.div
          key="modal"
          className="fixed bottom-0 left-0 z-50 flex w-full flex-col items-end"
          initial={{ y: '100%', height: 0 }}
          animate={{ y: '0', height: 'fit-content' }}
          exit={{ y: '100%' }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          ref={modalRef}
          onAnimationComplete={() => {
            // This observer takes care of any change on children size
            // that affects the modal's height. This is used on the
            // LoginSignup component to change the modal's border radius
            const resizeObserver = new ResizeObserver(() => {
              checkWindowHeight()
            })
            resizeObserver.observe(modalDivRef.current!)
            return () => {
              resizeObserver.disconnect()
            }
          }}
        >
          <>
            <div
              className={twMerge(
                'fixed h-0 w-full px-4',
                overlayFitsSnackBar ? 'top-0 z-20' : 'bottom-0 z-50',
              )}
            >
              <AnimatePresence>
                {snack !== null && displayMode === 'MODAL' && (
                  <SnackBar snack={snack} isMobile={true} />
                )}
              </AnimatePresence>
            </div>
            <div
              className={twMerge(
                'relative z-30 flex max-h-dvh w-full flex-col items-center justify-center overflow-scroll',
                hasRoundedBorder && 'rounded-t-lg',
                variation === 'light' ? 'bg-white' : 'bg-background-main',
              )}
              ref={modalDivRef}
            >
              <>
                {fullScreen && (
                  <DeleteIcon
                    onClick={handleModalClose}
                    className="absolute right-2 top-2 z-50"
                    variant="dark"
                  />
                )}
              </>
              {children}
            </div>
          </>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
