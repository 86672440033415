import { useState, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ProfileHeader } from '@/components/Memories/ProfileHeader'
import { EditProfileModal } from '@/components/Memories/EditProfileModal'
import { EventCard } from '@/components/Global/EventCard'
import { ProfilePictureModal } from '@/components/LoginSignup/ProfilePictureModal'

import { useGetProfileData } from '@/hooks/api/Profile'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'

import { isTruthy } from '@/helpers/validation'
import { Spinner } from '@/components/Global/Spinner'

export function MemoriesDesktop(): ReactElement {
  const [showEditProfileModal, setShowEditProfileModal] =
    useState<boolean>(false)
  const [showProfilePictureModal, setShowProfilePictureModal] =
    useState<boolean>(false)
  const [isParentClosing, setIsParentClosing] = useState<boolean>(false)

  const { username } = useParams()

  const {
    username: usernameStore,
    firstName: firstNameStore,
    lastName: lastNameStore,
  } = useUserStore()
  const { setToPath } = useRoutingStore()

  const {
    userProfileData,
    isLoading: isLoadingProfileData,
    refresh,
  } = useGetProfileData(username ?? '')

  const isAuthUser = usernameStore === userProfileData?.username

  const sectionTitle = isAuthUser
    ? 'Meus eventos'
    : `Eventos de @${userProfileData?.username ?? ''}`

  if (isLoadingProfileData)
    return (
      <div className="absolute left-0 top-0 flex size-full items-center justify-center">
        <Spinner
          borderWidth="border-4"
          borderColor="border-primary-main/50"
          bottomBorderColor="border-b-primary-main"
          height="h-12"
          width="w-12"
        />
      </div>
    )
  return (
    <div className="flex size-full flex-col gap-14 p-8">
      {userProfileData !== undefined && (
        <ProfileHeader
          userData={
            isAuthUser
              ? {
                  ...userProfileData,
                  firstName: firstNameStore,
                  lastName: lastNameStore,
                }
              : userProfileData
          }
          isAuthUser={isAuthUser}
          isLoading={isLoadingProfileData}
          openEditProfileModal={() => {
            setShowEditProfileModal(true)
          }}
          openEditProfilePictureModal={() => {
            setToPath('/memories/rodrigo')
            setShowProfilePictureModal(true)
          }}
        />
      )}
      <motion.div
        className="flex size-full flex-col gap-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        key="user-event-tickets"
      >
        {userProfileData?.stickers.length === 0 ? (
          <div className="flex h-64 grow flex-col items-center justify-center">
            <span className="text-xl text-white">
              <strong>@{userProfileData.username}</strong> ainda não tem nenhum
              evento 😢
            </span>
          </div>
        ) : (
          <h1 className="text-3xl font-semibold text-white">{sectionTitle}</h1>
        )}
        <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8">
          {userProfileData?.stickers
            ?.sort((stickerA, stickerB) => {
              if (
                new Date(stickerA.event.startDate) <
                new Date(stickerB.event.startDate)
              )
                return 1
              return -1
            })
            .map((sticker) => {
              const stickerIsGlued = isTruthy(sticker.glued)
              const imageToRender = stickerIsGlued
                ? sticker.imageKey
                : sticker.event.imageKey

              return (
                <div className="size-full" key={sticker.id}>
                  <EventCard
                    alias={sticker.event.alias}
                    key={sticker.event.alias}
                    imageUrl={imageToRender}
                    title={sticker.event.name}
                    startDate={sticker.event.startDate}
                    endDate={sticker.event.endDate}
                    location={
                      isTruthy(sticker.event.location.name)
                        ? sticker.event.location.name
                        : 'Local a definir'
                    }
                    variation="desktop"
                    to={`/wallet/${sticker.event.alias}`}
                    isSticker={stickerIsGlued}
                  />
                </div>
              )
            })}
        </div>
      </motion.div>

      {showEditProfileModal && (
        <EditProfileModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowEditProfileModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          refreshUserData={refresh}
        />
      )}
      {showProfilePictureModal && (
        <ProfilePictureModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowProfilePictureModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
    </div>
  )
}
