import { useEffect, useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { HomeBase } from '@/compositions/HomeBase'

import { useGetHomeEvents } from '@/hooks/api/Home'
import { usePostVerifyEmailOTP } from '@/hooks/api/Auth'

import { Loading } from '@/components/Global/Loading'
import { Spinner } from '@/components/Global/Spinner'
import { VerifyEmailSnackbar } from '@/components/Home/VerifyEmailSnackbar'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

import { useNavbarStore } from '@/store/navbar'
import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { useSnackbarStore } from '@/store/snackbar'

import { type SectionData } from '@/types/hooks/api/useHomeEvents'
import { isEventFinished } from '@/helpers/isEventFinished'

export function HomeMobile(): ReactElement {
  const [currentPage, setCurrentPage] = useState(0)
  const [otherEvents, setOtherEvents] = useState<SectionData[]>([])
  const [pastEvents, setPastEvents] = useState<SectionData[]>([])
  const [noNewEvents, setNoNewEvents] = useState(false)
  const [showVerifyEmailSnackbar, setShowVerifyEmailSnackbar] = useState(false)

  const { homeEventsData, isLoading, fetchOtherEvents } = useGetHomeEvents()
  const { postVerifyEmailOtp } = usePostVerifyEmailOTP()

  const searchParams = new URLSearchParams(document.location.search)
  const code = searchParams.get('code')

  const navigate = useNavigate()

  const { showNavbar } = useNavbarStore()
  const { verifiedEmail, userIsLogged, setVerifiedEmail } = useUserStore()
  const { setToPath, isPinging } = useRoutingStore()
  const { showSuccessSnack } = useSnackbarStore()

  async function loadMoreEvents(): Promise<void> {
    // Aggregate already shown event ids
    const idsToExclude: number[] = []

    idsToExclude.push(homeEventsData?.main?.id ?? 0)
    homeEventsData?.sections.forEach((sectionObject) => {
      sectionObject.events.forEach((event) => {
        idsToExclude.push(event.id)
      })
    })
    otherEvents?.forEach((event) => {
      idsToExclude.push(event.id)
    })
    pastEvents?.forEach((event) => {
      idsToExclude.push(event.id)
    })

    const result = await fetchOtherEvents(idsToExclude)

    if (result.otherEvents !== undefined) {
      const newEvents: SectionData[] = result.otherEvents
      if (newEvents.length < 10) {
        setNoNewEvents(true)
      }
      const today = new Date()
      const otherEventsFiltered = newEvents.filter((event) => {
        const eventDate = new Date(event.date)
        if (eventDate >= today) {
          return true
        }
        return false
      })
      setOtherEvents((prev) => [...prev, ...(otherEventsFiltered ?? [])])

      const pastEventsFiltered = newEvents.filter((event) => {
        if (isEventFinished(event.endDate)) return true
        return false
      })
      setPastEvents((prev) => [...prev, ...(pastEventsFiltered ?? [])])
    }
  }

  useEffect(() => {
    showNavbar()
  }, [])

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((current) => current + 1)
      }
    })

    const loadSentry = document.querySelector('#loadSentry')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLoading])

  useEffect(() => {
    if (currentPage > 0) {
      void loadMoreEvents()
    }
  }, [currentPage])

  useEffect(() => {
    if (homeEventsData?.otherEvents != null) {
      const today = new Date()
      const otherEventsFiltered = homeEventsData?.otherEvents.filter(
        (event) => {
          const eventDate = new Date(event.date)
          if (eventDate >= today) {
            return true
          }
          return false
        },
      )
      setOtherEvents(otherEventsFiltered)

      const pastEventsFiltered = homeEventsData?.otherEvents.filter((event) => {
        const eventDate = new Date(event.date)
        if (eventDate < today) {
          return true
        }
        return false
      })
      setPastEvents(pastEventsFiltered)

      if (homeEventsData?.otherEvents.length < 10) {
        setNoNewEvents(true)
      }
    }
  }, [homeEventsData])

  async function handleEmailValidation(): Promise<void> {
    if (code !== null && !userIsLogged && !isPinging) {
      setToPath('/?code=' + code)
      navigate('/login')
      return
    }

    if (verifiedEmail !== undefined && !verifiedEmail) {
      if (code !== null) {
        const result = await postVerifyEmailOtp({ code })
        if (result.status === 200) {
          showSuccessSnack({
            title: 'Email verificado com sucesso',
          })
          setVerifiedEmail(true)
        }
      } else {
        setTimeout(setShowVerifyEmailSnackbar, 1000, true)
        setTimeout(setShowVerifyEmailSnackbar, 5000, false)
      }
    }
  }

  useEffect(() => {
    void handleEmailValidation()
  }, [code, verifiedEmail, userIsLogged, isPinging])

  if (isLoading) {
    return <Loading hidden={!isLoading} />
  }
  return (
    <div className="flex min-h-full flex-col gap-8 bg-dark-black pb-16 pt-4">
      {homeEventsData?.main != null && (
        <HomeBase.MainMobile
          eventAlias={homeEventsData?.main.alias}
          imageKey={homeEventsData?.main.splashImageKey}
          titleImage={homeEventsData?.main.titleImageKey}
          title={homeEventsData?.main.title}
          date={datesToFormat(
            homeEventsData?.main.date,
            homeEventsData?.main.endDate,
          )}
          location={
            isTruthy(homeEventsData?.main.location)
              ? homeEventsData?.main.location
              : 'Local a definir'
          }
          tags={homeEventsData?.main.tags}
          onFire={homeEventsData?.main.onFire}
        />
      )}
      {homeEventsData?.sections
        ?.filter((section) => section.events.length > 0)
        .map((section, index) => {
          if (section.events.length === 1) {
            return (
              <HomeBase.Section title={section.title} key={index}>
                <HomeBase.Card
                  alias={section.events[0].alias}
                  imageUrl={section.events[0].splashImageKey}
                  startDate={section.events[0].date}
                  location={
                    isTruthy(section.events[0].location)
                      ? section.events[0].location
                      : 'Local a definir'
                  }
                  title={section.events[0].title}
                  variation="big"
                  onFire={section.events[0].onFire}
                  endDate={section.events[0].endDate}
                />
              </HomeBase.Section>
            )
          } else {
            return (
              <HomeBase.Carousel
                title={section.title}
                key={index}
                childrens={section.events?.map((event, index) => {
                  return (
                    <HomeBase.Card
                      alias={event.alias}
                      key={index}
                      title={event.title}
                      imageUrl={event.splashImageKey}
                      startDate={event.date}
                      variation="medium"
                      location={
                        isTruthy(event.location)
                          ? event.location
                          : 'Local a definir'
                      }
                      onFire={event.onFire}
                      endDate={event.endDate}
                    />
                  )
                })}
              ></HomeBase.Carousel>
            )
          }
        })}

      {otherEvents.length > 0 && (
        <HomeBase.Section title="Outros eventos">
          <div className="flex flex-col gap-1.5">
            {otherEvents.map((event, index) => {
              return (
                <HomeBase.Card
                  alias={event.alias}
                  key={index}
                  imageUrl={event.splashImageKey}
                  title={event.title}
                  startDate={event.date}
                  location={
                    isTruthy(event.location)
                      ? event.location
                      : 'Local a definir'
                  }
                  variation="small"
                  onFire={event.onFire}
                  endDate={event.endDate}
                />
              )
            })}
          </div>
        </HomeBase.Section>
      )}
      {pastEvents.length > 0 && (
        <HomeBase.Section title="Eventos passados">
          <div className="flex flex-col gap-1.5 pb-4">
            {pastEvents.map((event, index) => {
              return (
                <HomeBase.Card
                  alias={event.alias}
                  key={index}
                  imageUrl={event.splashImageKey}
                  title={event.title}
                  startDate={event.date}
                  location={
                    isTruthy(event.location)
                      ? event.location
                      : 'Local a definir'
                  }
                  variation="small"
                  endDate={event.endDate}
                />
              )
            })}
            {!noNewEvents && (
              <div
                id="loadSentry"
                className="my-8 flex w-full items-center justify-center gap-4 "
              >
                <Spinner
                  borderWidth="border-4"
                  borderColor="border-primary-main/50"
                  bottomBorderColor="border-b-primary-main"
                />
              </div>
            )}
          </div>
        </HomeBase.Section>
      )}
      <div className="fixed bottom-0 z-10 w-[90%]">
        <VerifyEmailSnackbar
          showSnackBar={
            showVerifyEmailSnackbar &&
            verifiedEmail !== undefined &&
            !verifiedEmail
          }
        />
      </div>
    </div>
  )
}
