import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isPast } from 'date-fns'

import { Loading } from '@/components/Global/Loading'
import { EventCard } from '@/components/Global/EventCard'

import { BsArrowLeftShort } from 'react-icons/bs'

import { useWalletStore } from '@/store/wallet'
import { useGlobalStore } from '@/store/global'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

import { dateToMonthYear } from '@/helpers/formatDate'

export function WalletHistory(): ReactElement {
  const [groupedPastEvents, setGroupedPastEvents] = useState<
    Record<string, IWalletTicketGroup[]>
  >({})
  const navigate = useNavigate()

  const { walletEvents, isLoadingWalletFetch } = useWalletStore()
  const { isMobile } = useGlobalStore()

  function splitPastEventsByMonthAndYear(events: IWalletTicketGroup[]): void {
    const result = events.reduce<Record<string, IWalletTicketGroup[]>>(
      (acc, event) => {
        const eventDate = new Date(event.startDate)
        const month = eventDate.getMonth()
        const year = eventDate.getFullYear()

        const key = new Date(year, month, 1, 12).toISOString()

        if (acc[key] === undefined) {
          acc[key] = []
        }

        acc[key].push(event)

        return acc
      },
      {},
    )
    setGroupedPastEvents(result)
  }

  useEffect(() => {
    if (walletEvents !== undefined) {
      const pastEvents: IWalletTicketGroup[] = walletEvents?.filter(
        (ticketGroup) => {
          const eventDate = new Date(ticketGroup.endDate)
          return isPast(eventDate)
        },
      )
      splitPastEventsByMonthAndYear(pastEvents)
    }
  }, [walletEvents])

  useEffect(() => {
    if (!isMobile) navigate('/wallet/')
  }, [isMobile])

  if (isLoadingWalletFetch) {
    return <Loading />
  }

  return (
    <div className="flex h-full min-h-full flex-col overflow-y-scroll bg-background-main p-4 pb-24">
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            navigate('/wallet')
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="text-2xl text-white">Meu histórico</h1>
      </div>
      <div className="mt-6 flex flex-col gap-6">
        {Object.entries(groupedPastEvents).map(([key, events]) => {
          return (
            <div key={key} className="flex w-full flex-col gap-1">
              <span className="text-xl font-black text-white">
                {dateToMonthYear(key)}
              </span>
              <div className="flex w-full flex-col gap-2">
                {events.map((event) => {
                  return (
                    <div key={event.alias} className="w-full">
                      <EventCard
                        alias={event.alias}
                        imageUrl={event.imageKey}
                        startDate={event.startDate}
                        location={event.location ?? 'Local a definir'}
                        title={event.title}
                        variation="small"
                        to={`${event.alias}`}
                        endDate={event.endDate}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
