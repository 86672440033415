import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { NotificationHistory } from '@/components/Notifications/NotificationHistory'
import { DeleteIcon } from '../../Icons/DeleteIcon'

import { useGetNotificationHistory } from '@/hooks/api/Notifications'
import { Spinner } from '../../Spinner'

interface HeaderNotificationsProps {
  showNotifications: boolean
  setShowNotifications: (v: boolean) => void
}

export function HeaderNotifications({
  setShowNotifications,
  showNotifications,
}: HeaderNotificationsProps): ReactElement {
  const { notificationHistoryData, isLoading } = useGetNotificationHistory()

  return (
    <AnimatePresence>
      {showNotifications && (
        <motion.div
          className="absolute bottom-[-410px] right-8 z-40 flex h-96 w-[500px] origin-top flex-col gap-1 rounded-lg bg-dark-black"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.2 }}
          key="notifications"
        >
          <div className="flex size-full flex-col gap-4 overflow-y-scroll p-4">
            <div className="flex w-full items-center justify-between">
              <h1 className="text-xl text-white">Notificações</h1>
              <DeleteIcon
                onClick={() => {
                  setShowNotifications(false)
                }}
              />
            </div>

            <div className="absolute -top-4 right-[83px] size-0 border-x-[18px] border-b-[28px] border-x-transparent border-b-dark-black" />

            <div className="size-full">
              {isLoading && (
                <div className="absolute left-1/2 top-1/2 z-20">
                  <Spinner
                    borderWidth="border-4"
                    borderColor="border-dark-black/50"
                    bottomBorderColor="border-b-dark-black"
                  />
                </div>
              )}
              {notificationHistoryData !== undefined && (
                <NotificationHistory
                  notificationHistoryData={notificationHistoryData}
                />
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
